/* Dark Mode Specific --------------------------------------------------------------------------------*/

.dark--overlay {
    filter: brightness(70%);
}

.light--overlay--dirty--code {
    filter: brightness(100%);
}

.inner--question--container--dark {
    background: #2f2f2f;
}

.question--counter--container--dark {
    filter: brightness(80%);
}

.question--title--dark {
    color: #c9c9c9 !important;
}

.theme--switch--dark {
    border: 2px solid #cacaca;
}

.theme--switch--dot--dark {
    background: #cacaca;
    transform: translateX(30px);
}

.separator--dark {
    color: #cacaca;
}

.line--dark {
    background: #cacaca !important;
}

.light--text {
    color: #c9c9c9 !important;
}

.answer--dark {
    border: 2px solid #155fb3;
    padding: 6px;
    cursor: pointer;
    background: #155fb3;
}

.answer--selected--dark {
    background: #979797;
    border: 2px solid #D6DBF5;
    color: #293264;
    animation-name: selectedSuspense;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

.answer--correct--dark {
    background: #1d8835;
    border: 2px solid #238939;
    animation-name: correctAnswer;
    animation-duration: 500ms;
    animation-iteration-count: 3;
    animation-fill-mode: forwards;
}

.answer--incorrect--dark {
    background: #903434;
    border: 2px solid #903434;
}

.user--incorrect--dark {
    background: #71b962;
    border: 2px solid #59ae6c;
}

.answer--text--dark {
    color: #e4e4e4 !important;
}

.answer--not--selected--dark {
    background: #9a5c58;
    border: 2px solid #a55757;
}

