.play--online--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
}

.play--online--inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    grid-row: 3 / 4;
    grid-column: 2 / -1;
}

.play--online--invite > input {
    width: 90%;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.play--online--invite > button {
    width: 90%;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #ccc;
    color: #fff;
}

.play--online--invite {
    width: 100%;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
    clip-path: inset( 0px 0px -15px 0px);
    padding: 20px 20px;
}

.randomRequest {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 20px;
}

.loading--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    margin-left: 10px;
    margin-top: 2px;
}

.loading--dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--text-color);
    margin: 0 2px;
    opacity: 0;
}

.loading--dot#loading--one {
    animation: loadingDot 3s ease-in-out infinite;
}

.loading--dot#loading--two {
    animation: loadingDot 3s ease-in-out 200ms infinite;
}

.loading--dot#loading--three {
    animation: loadingDot 3s ease-in-out 400ms infinite;
}

.game--request {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 10px 20px;
    background-color: rgb(223, 145, 2);
    width: 100%;
}

.game--request>h3 {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
    color: #000000;
}

.game--request--buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    margin-left: auto;
}

.game--request--buttons>button,
.games--container>button {
    margin-left: 10px;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.prev--request {
    width: 100%;
}

#play--button {
    grid-column: 2 / -1;
    grid-row: 1 / 3;
    background-color: #333333;
    border: 2px solid #222222;
    margin: 0;
    color: #ffffff;
}

#accept--button {
    background: #76fa76;
    border: 2px solid #00c000;
}

#reject--button {
    background: #fe8686;
    border: 2px solid #9a0000;
}

.check--cross--marks {
    width: 100%;
    height: 100%;
}

.game--items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--root-background-color);
}

.game--items--their--turn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0px 7px 9px -5px rgba(0, 0, 0, 0.150);
}

.game--items--finished {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0px 7px 9px -7px rgba(0, 0, 0, 0.150);
}

.game--requests {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.games--container {
    display: flex;
    width: 100%;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    background: rgb(0, 128, 255);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.734);
}

.games--container--their--turn {
    background: #d7d7d7;
}

.games--container>h1 {
    font-weight: 700;
}

.stats--container {
    display: flex;
    padding: 0;
    margin: 0;
}

#round {
    font-size: 1.1em;
    margin: 0;
}

#score {
    font-size: 1.1em;
    margin: 0 10px;
}

.games--container>h1 {
    margin: 0;
    padding: 0;
    font-size: 1.4em;
}

.games--container>h3 {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
}

.modal--container {
    position:absolute;
    top: 32.5%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 75%;
    min-width: 150px;
    max-width: 500px;
    height: 37.5%;
    background: #777777;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
    z-index: 100;
    transform: translateX(-50%)
}

.modal--content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.modal--title {
    margin-top: 20px;
}

.modal--body {
    margin: 0 20px;
}

.modal--button {
    width: 100%;
    height: 70px;
    border: 1px solid #34cc34;
    background-color: #34cc34;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 1.3rem;
}

.currentGames--container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: 2 / -1;
    grid-row: 1 / -1;
}