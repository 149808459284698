@media (min-width: 1000px) {
    #root {
        max-width: 1100px;
        margin: 0 auto 0 auto;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        /* clip-path: inset(0px 0px -15px 0px); */
    }

    .header {
        left: 0;
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.50);
    }

    .bottom--nav,
    .bottom--nav--dark {
        left: 0;
        box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.50);
    }

    .header--container {
        flex-direction: row-reverse;
    }

    .welcome--container {
        padding: 20px 20px 0 20px;
    }

    #resetButton {
        left: 50%;
        width: 700px;
        -webkit-animation: slide-in-desktop 500ms ease-in-out forwards;
        animation: slide-in-desktop 500ms ease-in-out forwards;

    }

    .inner--question--container {
        min-height: calc(100vh - 495px);
    }

    .question--title {
        font-size: 2.5rem;
    }

    .answer--container {
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(8, 4vh);
    }

    #resetButtonDark {
        left: 50%;
        width: 700px;
        -webkit-animation: slide-in-desktop 500ms ease-in-out forwards;
        animation: slide-in-desktop 500ms ease-in-out forwards;

    }

    .about-me--container {
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.50);
        clip-path: none;
    }

    .competence--item {
        position: relative;
    }

    .signup--card {
        width: 500px;
        height: fit-content;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, calc(50% + 85px)) !important;
        clip-path: none;
        justify-content: space-between;
    }

    .signup--card-body {
        height: 100%;
    }

    .signup--card-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #displayName {
        margin-top: auto;
    }

    .auth--button {
        margin-top: auto;
    }

    .info--buttons--container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        align-self: center;
        width: 100%;
        height: 100%;
    }

    .info--button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(223, 145, 2);
        /* background-repeat: no-repeat;
        background-size: 15rem;
        background-blend-mode: overlay; */
        box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
        cursor: pointer;
        width: 350px;
        height: 70px;
        color: #2f2f2f;
        border-bottom: 2px solid #333333;
        z-index: 999;
    }

    .features--button {
        position: absolute;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        left: 0;
        top: 50%;
        transform: translateX(-142px) translateY(-50%) rotate(90deg);
    }

    .features--button>h1 {
        margin: 0;
    }

    .about--button {
        position: absolute;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        right: 0;
        top: 50%;
        transform: translateX(142px) translateY(-50%) rotate(-90deg);
    }

    .about--button>h1 {
        margin: 0;
    }

    /* .info--instant--access {
        height: 340px;
        width: 380px;
        border-radius: 49%;
    }*/

    .info--instant--access:active {
        transform: translateY(0);
        animation: none;
    } 

    .about-me--text {
        width: 80%;
        min-height: 400px;
    }

    .competence--row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 20px 0;
    }

    .popup--modal {
        position: absolute;
        bottom: 100%;
        display: none;
        width: 200%;
        padding: 20px;
        outline: 1px solid var(--text-accent-color);
        background-color: var(--accent-color);
        color: var(--text-accent-color);
        border-radius: 5px;
        font-weight: 500;
        font-size: 1.1rem;
        overflow: auto;
        max-height: fit-content;
    }

    .popup--modal.react,
    .popup--modal.firebase {
        left: 0;
    }

    .popup--modal.javascript,
    .popup--modal.bootstrap {
        right: 0;
    }

    .popup--modal--arrow {
        position: absolute;
        display: none;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid var(--text-accent-color-arrow);
        box-shadow: 0px -1px 0 0 var(--text-accent-color-arrow-box);
    }

    .competence--item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        height: 200px;
        width: 200px;
    }

    .competence--item>h3 {
        margin-top: auto;
    }

    .competence--item>img {
        height: 100px;
    }

    .about--links--container {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .about--button--linkedin {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .links--button.linkedIn {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

}

@media (min-width: 1270px) {


    .popup--modal {
        width: 200%;
    }

    .popup--modal.react,
    .popup--modal.firebase {
        left: auto;
    }

    .popup--modal.javascript,
    .popup--modal.bootstrap {
        right: auto;
    }
}

@media (min-width: 800px) {
    @media (max-width: 1000px) {

        .about--links--container {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .about--button--linkedin {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .links--button.linkedIn {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .welcome--container {
            padding: 20px 20px 0 20px;
        }

        /* .info--instant--access {
            margin-top: 30px;
            height: 300px;
            width: 340px;
            border-radius: 49%;
        }

        .info--instant--access:active {
            box-shadow:
                inset 0px -12px 2px 0 rgba(0, 0, 0, 0.25),
                0 5px 0 0 #545454,
                0 5px 5px 0 #545454,
                inset 0 -10px 0 0 #545454,
                inset 0 3px 0 0 #da0f00,
                inset 0 7px 0 0 rgba(255, 255, 255, 0.25);
            height: 280px;
            transform: translateY(0px);
            margin-bottom: 20px;
            animation: none;
        } */

        #react::after {
            top: 25px;
        }

        #react::before {
            top: 35px;
        }

        .about-me--text {
            width: 60%;
            min-height: 400px;
        }

        .competence--row {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .competence--button {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }

        .popup--modal {
            display: none;
            width: 100%;
            background-color: var(--accent-color);
            overflow: hidden;
            max-height: 0;
        }

        .popup--modal>p {
            margin: 0;
            font-size: 1.1rem;
            font-weight: 500;
            padding: 20px;
        }

        .start--main {
            padding-top: 80px;
            overflow: auto;
            scrollbar-width: none;
            position: relative;
        }

        .competence--item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            height: 200px;
            width: 200px;
        }

        .competence--item>h3 {
            margin-top: auto;
        }

        .competence--item>img {
            height: 100px;
        }


    }
}

@media (max-height: 700px) {
    @media (min-width: 600px) {


        /* .info--instant--access {
            margin-top: 30px;
            height: 240px;
            width: 260px;
            border-radius: 49%;
        }

        .info--instant--access:active {
            box-shadow:
                inset 0px -12px 2px 0 rgba(0, 0, 0, 0.25),
                0 5px 0 0 #545454,
                0 5px 5px 0 #545454,
                inset 0 -10px 0 0 #545454,
                inset 0 3px 0 0 #da0f00,
                inset 0 7px 0 0 rgba(255, 255, 255, 0.25);
            height: 280px;
            transform: translateY(0px);
            margin-bottom: 20px;
            animation: none;
        } */
    }
}