body,
html {}

.welcome--container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px 20px 0.5vh 20px;
}

.bottom--nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: #979797;
    color: #000000;
    font-size: 1.2em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
    /* clip-path: inset(-15px 0px 0px 0px); */
}

.bottom--nav--dark {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: #2f2f2f;
    color: #cacaca;
    font-size: 1.2em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
    /* clip-path: inset(-15px 0px 0px 0px); */
}

.newGame {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.nav--logo {
    width: 30px;
    height: 30px;
}

.nav--button {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #333333;
    border-right: 1px solid #333333;
    cursor: pointer;
    position: relative;
}

.nav--button>h1 {
    font-size: 1.2em;
    margin: 0;
    text-align: center;
}

.greenPlay {
    background: rgb(0, 255, 102);
    color: #2f2f2f;
}

#local {
    position: absolute;
    font-size: 0.7em;
    top: 75px;
}

.active--games--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
}

.currentGames--container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row: none;
    grid-column: none;
}

.start--new--game--outer {
    display: flex;
    justify-content: center;
    height: auto;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    clip-path: inset(0px 0px -15px 0px);
}

.start--new--game {
    width: 100%;
    height: 100px;
    background-color: #00d60f;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
}


.players--container {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    word-break: break-all;
    padding: 10px 10px 0 10px;
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    height: fit-content;
    position: relative;
}

.win--container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.win--container--left {
    width: 40%;
}

.win--container--middle {
    width: 20%;
}

.win--container--right {
    width: 40%;
}

.round--row {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-height: 60px;
}

.player--title {
    font-size: 1.6em;
    margin: 0;
}

.game--left {
    min-height: 100%;
    grid-column: 1 / 2;
    display: flex;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 1fr);
    flex-direction: column;
    align-items: center;
    width: 40%;
    justify-content: space-evenly;
}

.game--left>h1 {
    font-size: 2.5em;
    margin: 0;
    grid-row: 1 / 2;
    justify-self: center;
}

.game--right {
    min-height: 100%;
    grid-column: 3 / -1;
    display: flex;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 1fr);
    flex-direction: column;
    align-items: center;
    width: 40%;
    justify-content: space-evenly;

}

.game--right>h1 {
    font-size: 2.5em;
    margin: 0;
    grid-row: 1 / 2;
    justify-self: center;
}

.game--middle {
    min-height: 100%;
    grid-column: 2 / 3;
    display: flex;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 1fr);
    flex-direction: column;
    align-items: center;
    width: 20%;
    justify-content: center;
    max-height: 60px;
}

.game--middle>h1 {
    font-size: 2.5em;
    margin: 0;
    grid-row: 1 / 2;
    justify-self: center;
    color: transparent;
}

.vs {
    grid-column: 1 / -1;
    justify-self: center;
    align-self: center;
    font-size: 1.2em;
    margin: 0;
}

.roundSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 100%;
}

.roundSection>p {
    margin: 0;
    font-weight: 700;
}

.roundSection>.category--p {
    font-size: 0.8em;
    font-weight: 500;
}

.games--container>.your--turn {
    min-width: 100px;
    float: right;
    font-size: 1.2em;
    margin: 0;
    cursor: pointer;
}

.round {
    height: 25px;
    width: 80%;
    display: flex;
    border-radius: 7px;
    justify-content: space-evenly;
    justify-self: center;
    box-shadow: 0px 0px 5px 0px var(--accent-color);
    overflow: hidden;
}

.answer--boxes {
    height: 100%;
    width: 100%;
}

.sideBox0 {
    border-right: 1px solid var(--dark-accent-color);
}

.sideBox1,
.sideBox2,
.sideBox3 {
    border-right: 1px solid var(--dark-accent-color);
}



.start--new--game>h1 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
}

.choose--category--header {
    text-align: center;
    padding: 40px 20px 0 20px;
}

.header {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    z-index: 9999;
    height: 85px;
}

/* .quiz--title {
        position: relative;
        height: auto;
        right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        padding-bottom: 0;
        width: auto;
    } */

.logo {
    font-size: 2.5rem;
    text-decoration: none;
    color: #1a1a1a;
    padding: 0 30px;
    margin-bottom: 6px;
}

.logo:hover {
    color: currentColor;
}

.nav--title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    margin: 0;
    margin-top: 15px;
}

.title {
    font-size: 3rem;
    justify-self: flex-end;
}

.category--title {
    font-size: 1.5rem;
    text-align: center;
    padding: 0 30px;
}



#back--button {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 20px;
    width: 20px;
    height: 36px;
    opacity: 70%;
    cursor: pointer;
}

.short {
    color: rgba(0, 0, 0, 0.7);
    margin: 0 10px 0 0;
}

.start--categories--container {
    position: relative;
    height: 200px;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    bottom: 0;
    margin-top: auto;
}

.separator {
    display: none;
}

.hold,
.initial--hold {
    display: none;
}

.start--categories {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    color: rgb(0, 0, 0);
    text-shadow: 0px 0px 10px #ffffff39;
    font-size: 1.3rem;
    font-weight: 700;
    cursor: pointer;
    padding: 0;
    border-radius: 0;
    box-shadow: inset 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.start--categories--selected {
    box-shadow: none;
    transform: scale(100%);
}

.allGrid {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    border-radius: 0;
    z-index: 0;
}

.generalGrid {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    border-radius: 0;
    z-index: 0;
}

.entertainmentGrid {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    border-radius: 0;
    /* box-shadow: inset 0px -30px 5px -30px #000000; */
    z-index: 5;
}

.scienceGrid {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    border-radius: 0;
    /* box-shadow: inset 0px -30px 5px -30px #000000; */
    z-index: 5;
}

.start--button {
    background: #2f2f2f;
    color: #cacaca;
    width: 100vw;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    cursor: pointer;
    margin-top: 0;
    font-weight: 700;
    font-size: 2.5rem;
    align-self: center;
    z-index: 10;
    padding: 0 10% 0 10%;
}

#start--button--arrow {
    height: 35px;
    width: 15px;
    margin-left: auto;
    animation: arrow 3s infinite;
}

.sub--categories--container {
    position: absolute;
    width: 100%;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: 0;
    text-align: center;
    background: rgba(240, 240, 240, 0);
    border-radius: 0;
    justify-self: center;
    display: grid;
    grid-auto-rows: 50px;
    grid-template-columns: 1fr 1fr;
    z-index: 15;
    gap: 0;
    overflow: hidden;
    transform: translateY(140%)
}

.sub--categories--container--outer {
    height: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    align-self: flex-end;
    grid-column: 1 / -1;
}

.sub--categories--container--inner {
    height: 15px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.08);
    grid-column: 1 / -1;
    border-radius: 3px;

}

.sub--categories--image {
    width: 8px;
    height: 50px;
    transform: rotateZ(90deg) rotateY(0deg);
}

#hide {
    /* transform: translateY(100%); */
    display: none;
}

.other--main--selected {
    opacity: 40%;
}

.theme--switch {
    z-index: 2;
    top: auto;
    left: 0;
    margin: auto 30px;
}

#subAnimationUp {
    -webkit-animation: sub-category-reveal 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
    animation: sub-category-reveal 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards;

}



#subAnimationDown {
    -webkit-animation: sub-category-hide, other-cat-selected 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
    animation: sub-category-hide, other-cat-selected 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
}



#mainAnimationDown {
    -webkit-animation: main-category-hide 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
    animation: main-category-hide 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
}



#mainAnimationUp {
    -webkit-animation: main-category-reveal 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
    animation: main-category-reveal 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
}

#general1,
#general2,
#general3,
#general4 {
    background: #268bff;
}

#entertainment1,
#entertainment2,
#entertainment3,
#entertainment4 {
    background: #937dff;
}

#science1,
#science2,
#science3,
#science4 {
    background: #57ecdf;
}

#allGeneral,
#allEntertainment,
#allScience {
    grid-column: 1 / -1;
}

#allGeneral {
    background: #227de4;
}

#allEntertainment {
    background: #816fe0;
}

#allScience {
    background: #48c6bb;

}

#allGeneralBanner {
    box-shadow: none;
    margin-top: auto;
    height: 30px;
    /* background: #1e6fcb; */
    background: #dbdbdb
}

#allEntertainmentBanner {
    box-shadow: none;
    margin-top: auto;
    height: 30px;
    /* background: #7362c7; */
    background: #dbdbdb
}

#allScienceBanner {
    box-shadow: none;
    margin-top: auto;
    height: 30px;
    /* background: #3ba299; */
    background: #dbdbdb
}



.main--categories {
    padding-left: 0px;
    justify-content: center;
}

.question--container {
    min-height: 100%;
    height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.flex-row {
    margin-top: auto;
    height: 120px;
}

.question--title {
    font-size: 1.5rem;
}

.inner--question--container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 200px;
    padding: 40px 20px;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
    clip-path: inset(0 0 -15px 0);
}

.reminder--container {
    position: absolute;
    inset: auto 0 0 0;
    text-align: center;
    padding: 0 20px 20px 20px;
    opacity: 0;
    animation: reminder 5s 5s infinite;
}

.reminder--container::after {
    content: '';	
    position: absolute;
    width: 50px;
    height: 50px;
    top: -60px;
    right: 30%;
    box-shadow: 
    2px 2px 0px 0px var(--pulse-color), 
    -2px -2px 0px 0px var(--pulse-color),
    -2px 2px 0px 0px var(--pulse-color),
    2px -2px 0px 0px var(--pulse-color),
    0 0 6px 0px var(--pulse-accent-color), 
    0 0 6px 0px var(--pulse-accent-color),
    0 0 6px 0px var(--pulse-accent-color),
    0 0 6px 0px var(--pulse-accent-color)
    ;
    border-radius: 50%;
    animation: pulse 5s ease-in-out 75ms infinite;
}

.reminder--container::before {
    content: '';	
    position: absolute;
    width: 30px;
    height: 30px;
    top: -50px;
    right: calc(30% + 10px);
    box-shadow: 
    2px 2px 0px 0px var(--pulse-color), 
    -2px -2px 0px 0px var(--pulse-color),
    -2px 2px 0px 0px var(--pulse-color),
    2px -2px 0px 0px var(--pulse-color),
    0 0 6px 0px var(--pulse-accent-color), 
    0 0 6px 0px var(--pulse-accent-color),
    0 0 6px 0px var(--pulse-accent-color),
    0 0 6px 0px var(--pulse-accent-color)
    ;
    border-radius: 50%;
    animation: pulse 5s ease-in-out infinite;
}

.correct--answer--counter {
    color: #5870e7;
    font-size: 1.2rem;
}

.answer--container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 3vh);
}

.answer--container--true--false {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, 4vh);
}

.answer--button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #293264;
    border: 0;
    background: #268bff;
    padding: 5px;
    cursor: pointer;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    max-width: 100%;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 1);
}

.answer--box--inner {
    width: fit-content;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    min-width: 100%;
}

.answer--text {
    font-size: 2.5vh;
    font-weight: bold;
}

#resetButton {
    margin-top: auto;
    position: absolute;
    justify-self: flex-end;
    bottom: 0;
    height: 75px;
    width: 100%;
    font-size: 1.8rem;
    color: #ebebeb;
    background: #2f4ad0;
    -webkit-animation: slide-in 500ms ease-in-out forwards;
    animation: slide-in 500ms ease-in-out forwards;
}

#resetButtonDark {
    margin-top: auto;
    position: absolute;
    justify-self: flex-end;
    bottom: 0;
    height: 75px;
    width: 100%;
    font-size: 1.8rem;
    color: #cacaca;
    background: #2f2f2f;
    -webkit-animation: slide-in 500ms ease-in-out forwards;
    animation: slide-in 500ms ease-in-out forwards;
}

.answer--correct--light {
    background: #19f849;
    -webkit-animation: correctAnswer 500ms ease-in-out 3 forwards;
    animation: correctAnswer 500ms ease-in-out 3 forwards;
}


.answer--incorrect--light {
    background: #ff7e7e;
}

.user--incorrect--light {
    background: #d3ffca;
}

.answer--not--selected--light {
    background: #ffc4c0;
}


.quiz--countdown--outer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px !important;
    box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
    margin: auto 0 0 0;
}

.quiz--countdown--inner {
    width: 100%;
    height: 5px;
    background: rgb(0, 255, 102);
    box-shadow: 0px 0px 5px 1px rgb(0, 255, 102, 0.4);
    clip-path: inset( -10px -10px 0 0);
    /* -webkit-animation: quizCountdown 15s linear forwards;
    animation: quizCountdown 15s linear forwards; */
}

#answer0 {
    grid-column: 1 / 5;
    grid-row: 1 / 5;
}

#answer1 {
    grid-column: 5 / 9;
    grid-row: 1 / 5;
}

#answer2 {
    grid-column: 1 / 5;
    grid-row: 5 / 9;
}

#answer3 {
    grid-column: 5 / 9;
    grid-row: 5 / 9;
}



.answer--selected--light {
    background: #D6DBF5;
    border: 2px solid #D6DBF5;
    color: #293264;
    -webkit-animation: selectedSuspense 1s ease-in forwards;
    animation: selectedSuspense 1s ease-in forwards;
}

.loading--title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.question--counter--container {
    top:0;
    height: 25px;
    width: 50%;
    max-width: 600px;
    display: flex;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    justify-content: space-evenly;
    justify-self: center;
    box-shadow: inset 0px 0px 3px 0px rgb(0 0 0 / 75%);
    overflow: hidden;
}

.question--counter {
    position: relative;
    height: 100%;
    width: 100%;
}

.alert {
    position: absolute !important;
    top: 0;
    z-index: 99999 !important;
    animation: alertSlideIn 3s ease-in-out forwards;
    max-width: 90%;
    text-align: center;
}

.notification {
    position: absolute !important;
    top: 0;
    z-index: 99999 !important;
    max-width: 90%;
    text-align: center;
    display: flex;
    gap: 10px;
    align-items: center;
}

.error--container {
    width: 100%;
    height: 20%;
    position: absolute;
    margin: 85px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.notification--container {
    width: 100%;
    height: 100px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 99999 !important;
}

.error--container div:nth-child(1n) {
    margin-top: -85px;
}

.invite--button {
    border: none;
    background: #181818;
    padding: 5px;
    border-radius: 5px;
}

.header--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 1100px;
}

@media (max-width: 500px) {
    .welcome--container {
        padding: 20px 20px 0 20px;
    }

    .features--button {
        transform: translateX(-102px) translateY(100%) rotate(90deg);
    }

    .about--button {
        transform: translateX(102px) translateY(100%) rotate(-90deg);
    }

    
}

@media (max-width: 450px) {
    .games--container {
        font-size: 0.8rem;
    }

    .games--container>.your--turn {
        min-width: 30px;
        text-align: end;
    }

    .games--container > div > h1 {
        font-size: 1.3rem;
    }
}