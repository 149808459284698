* {
    box-sizing: border-box;
    font-family: "Karla", sans-serif;
}

:root {
    --body-animation: none;
    --body-filter: none;
    --root-background-color: none;
    --text-color: none;
    --accent-color: none;
    --dark-accent-color: none;
    --text-accent-color-arrow: none;
    --text-accent-color-arrow-box: none;
    --pulse-color: none;
    --pulse-accent-color: none;
    --body-height: 100vh;
}

body {
    --body-animation: none;
    --body-filter: none;
    --text-color: none;
    --accent-color: none;
    --dark-accent-color: none;
    --text-accent-color-arrow: none;
    --text-accent-color-arrow-box: none;
    --pulse-color: none;
    --pulse-accent-color: none;
    --body-height: 100vh;
}

body::before {
    content: '';
    background-image: url(../img/background.jpg);
    background-size: 100vw 100vh;
    background-repeat: no-repeat;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    animation: var(--body-animation);
    filter: var(--body-filter);
}

html, body {
    height: var(--body-height);
}

body,
html,
#root {
    margin: 0;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    min-width: 250px;
}

#root {
    /* padding-bottom: env(safe-area-inset-bottom, 0.5vh); */
    background-color: var(--root-background-color);
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
}

/* Util ----------------------------------------------------------------------------------------------*/

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.other--selected {
    opacity: 40%;
}

.other--main--selected {
    opacity: 40%;
}

#subAnimations {
    animation: fade-in 150ms;
}

#fadeout {
    animation: fade-out 150ms;
}

#fadeTitle {
    animation: fade-title 150ms;
}

.initial--hold {
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: 15px;
    margin-bottom: 0;
    opacity: 0.7;
}

.hold {
    font-size: 0.8rem;
    opacity: 0.7;
    margin: 0;
}

.noScroll {
    overflow: hidden !important;
}

.negativeText {
    color: var(--accent-color);
}

.blackText {
    color: black;
}

/* StartScreen.js ----------------------------------------------------------------------------------------------*/

.start--main {
    min-height: var(--body-height);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 85px 0 100px 0 !important;
    justify-self: center;
    margin: 0 auto;
    background-color: var(--root-background-color);
    color: var(--text-color);
    position: relative;
}

.start--main--logged--in {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 1fr 100px;
    padding: 100px 20px 0 20px;
    margin: 0 auto;
    gap: 20px;

}

.start--main--inner {
    height: 100% !important;
}

.category--container {
    height: calc(var(--body-height) - 185px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.button--container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main--container {
    width: 100%;
    height: calc(var(--body-height) - 85px);
    min-width: 200px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: center;
    padding-left: 0;
    position: relative;
}

.game--content {
    width: 100%;
    min-height: 100%;
    height: 100%;
    min-height: calc(var(--body-height) - 185px);
    display: flex;
    flex-direction: column;
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-rows: 1fr 8fr;
    justify-content: space-evenly;
}

.main--categories {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    font-size: 1.3rem;
    color: rgb(0, 0, 0);
    text-shadow: 0px 0px 10px #ffffff39;
    cursor: pointer;
    padding-left: 20px;
    border-radius: 10px;
    z-index: 2;
}

.sub--categories--container {
    position: absolute;
    width: 100%;
    margin: 0;
    left: 0;
    right: 0;
    top: 220px;
    padding: 0;
    text-align: center;
    background: rgba(240, 240, 240, 0);
    border-radius: 0;
    justify-self: center;
    display: grid;
    grid-auto-rows: 50px;
    grid-template-columns: 1fr 1fr;
    z-index: 15;
    gap: 0;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    line-height: 0.9;
}

.sub--categories {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 0, 0);
    text-shadow: 0px 0px 10px #ffffff39;
    font-size: 1.3rem;
    font-weight: 700;
    cursor: pointer;
    padding: 0;
    border-radius: 0;
    box-shadow: inset 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.start--categories--selected {
    box-shadow: inset 0px 0px 3px 3px rgba(0, 0, 0, 0.15);
    z-index: 5;
}


.noll {
    display: none;
}

.theme--switch {
    border: 2px solid #000000;
    width: 50px;
    height: 20px;
    padding: 2px;
    border-radius: 10px;
    cursor: pointer;
}

.theme--switch--dot {
    background: #000000;
    height: 12px;
    width: 12px;
    height: 100%;
    border-radius: 50%;
}

.sub--title--container {
    grid-column: 1 / -1;
    grid-row: -1 / 1;
    display: flex;
    justify-content: center;
}

.sub--title--container>h1 {
    font-weight: 700;
    font-size: 1.6rem;
}

.separator {
    display: flex;
    align-items: center;
    grid-row: 4 / 5;
    grid-column: 2 / -2;
}

.separator h2 {
    font-size: 1.1rem;
    padding: 0 1rem;
    font-weight: 300;
}

.separator>.line {
    flex: 1;
    height: 1px;
    background-color: #000;
}

.loading--title {
    animation: loading 700ms infinite;
}

@keyframes loading {
    0% {
        opacity: 50%
    }

    50% {
        opacity: 100%
    }

    100% {
        opacity: 50%
    }
}

#all {
    background: rgb(0, 213, 255);
}

#general1,
#general2,
#general3,
#general4 {
    background: #268bff;
}

#entertainment1,
#entertainment2,
#entertainment3,
#entertainment4 {
    background: #937dff;
}

#science1,
#science2,
#science3,
#science4 {
    background: #57ecdf;
}

#allGeneral,
#allEntertainment,
#allScience {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
}

.start--inner {
    display: flex;
    flex-direction: column;
}

.nav--title {
    font-size: 1rem;
    font-weight: 700;
    margin: 40px 0 auto 0;
}

.category--title {
    margin: 0;
}

.start--button {
    background: #333333;
    color: #FFFFFF;
    width: 290px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1.2rem;
    justify-self: center;
    z-index: 10;
    grid-row: 3 / 4;
}

.login--button {
    position: absolute;
    top: 30px;
    left: 30px;
    background: #333333;
    color: #FFFFFF;
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1.2rem;
    align-self: center;
}

/* Main/Questions ----------------------------------------------------------------------------------------------*/

.main--button {
    background: #333333;
    color: #FFFFFF;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    margin-top: 50px;
    font-weight: 500;
    font-size: 1.2rem;
    align-self: center;
}

#back--button {
    position: absolute;
    top: 40px;
    left: 30px;
    width: 20px;
    height: 36px;
    opacity: 70%;
    cursor: pointer;
    filter: brightness(200%)
}

.question--title {
    font-weight: 700;
    font-size: 1.2em;
    color: #4D5B9E;
    cursor: pointer;
}

#answer0 {
    grid-column: 1 / 5;
    grid-row: 5 / 9;
}

#answer1 {
    grid-column: 5 / 9;
    grid-row: 5 / 9;
}

#answer2 {
    grid-column: 1 / 5;
    grid-row: 1 / 5;
}

#answer3 {
    grid-column: 5 / 9;
    grid-row: 1 / 5;
}

.answer--correct--light {
    background: #94D7A2;
    border: 2px solid #94D7A2;
    -webkit-animation: correctAnswer 500ms ease-in-out 3 forwards;
    animation: correctAnswer 500ms ease-in-out 3 forwards;
}


.answer--incorrect--light {
    background: #ff7e7e;
    border: 2px solid #F8BCBC;
}

.user--incorrect--light {
    background: #e5ffe0;
    border: 2px solid #94D7A2;
}

.answer--not--selected--light {
    background: #ffc4c0;
    border: 2px solid #ff9393;
}

.answer--selected--light {
    background: #D6DBF5;
    border: 2px solid #D6DBF5;
    color: #293264;
    -webkit-animation: selectedSuspense 1s ease-in forwards;
    animation: selectedSuspense 1s ease-in forwards;
}

.question--counter--container {
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    width: 50%;
    height: 10%;
    margin-bottom: 10px;
    z-index: 10;
}

.question--counter {
    /* border: 1px solid #c9c9c9; */
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0px 0px 1px 0px var(--root-background-color);
}

.box--default {
    background: var(--accent-color);
}

.box--correct {
    background: rgb(52, 204, 52)
}

.box--incorrect {
    background: rgb(255, 89, 89)
}

.box--locked {
    background: #b0b0b0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.check--button {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.correct--answer--counter {
    color: #3e4a86;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.allGrid {
    background: rgb(0, 213, 255);
} 

.generalGrid {
    background: #268bff;
}

.entertainmentGrid {
    background: #7a5fff;
}

.scienceGrid {
    background: #00ffea;
}

#no--cursor,
.no--cursor {
    cursor: default;
}

#allGeneral,
#allGeneralBanner,
#allEntertainment,
#allEntertainmentBanner,
#allScience,
#allScienceBanner {
    grid-column: 1 / -1;
}

#allGeneral {
    background: #227de4;
}

#allEntertainment {
    background: #816fe0;
}

#allScience {
    background: #48c6bb;

}

#allGeneralBanner {
    display: flex;
    justify-content: center;
    box-shadow: none;
    margin-top: auto;
    height: 30px;
    /* background: #1e6fcb; */
    cursor: default;
    font-size: 1.7rem;
    font-weight: 700;
}

#allEntertainmentBanner {
    display: flex;
    justify-content: center;
    box-shadow: none;
    margin-top: auto;
    height: 30px;
    /* background: #7362c7; */
    cursor: default;
    font-size: 1.7rem;
    font-weight: 700;
}

#allScienceBanner {
    display: flex;
    justify-content: center;
    box-shadow: none;
    margin-top: auto;
    height: 30px;
    /* background: #3ba299; */
    cursor: default;
    font-size: 1.7rem;
    font-weight: 700;

}

#backButton {
    width: 20px;
}

.info--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 100%;
}

.instant-access--container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.info--instant--access {
    transform: translateY(40px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    max-height: 70%;
    background: #da0f00;
    text-align: center;
    border-radius: 49%;
    padding: 20px;
    aspect-ratio: 1 / 1;
    box-shadow: 
    inset 0px -25px 0 0 rgba(0, 0, 0, 0.25), 
    0 5px 0px 0 #545454, 
    0 5px 10px 0 #545454, 
    inset 0 -10px 0 0 #545454,
    inset 0 3px 0 0 #da0f00,
    inset 0 10px 0 0 rgba(255, 255, 255, 0.25)
    ;
    cursor: pointer;
    animation: shakeButton 5s ease-in-out infinite;
    color: #000000;
}

.info--instant--access:active {
    box-shadow: 
    inset 0px -12px 2px 0 rgba(0, 0, 0, 0.25), 
    0 5px 0 0 #545454, 
    0 5px 5px 0 #545454, 
    inset 0 -10px 0 0 #545454,
    inset 0 3px 0 0 #da0f00,
    inset 0 7px 0 0 rgba(255, 255, 255, 0.25)
    ;
    height: 38%;
    transform: translateY(-10%);
    animation: none;
}

.info--instant--access > h3 {
    transform: rotateX(-30deg);
    margin-bottom: 30px;
    text-shadow: 0px 2px 0px rgb(255, 255, 255, 0.3);
}

.info--text {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    text-align: center;
}

.info--button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(223, 145, 2);
    /* background-repeat: no-repeat;
    background-size: 15rem;
    background-blend-mode: overlay; */
    box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    width: 250px;
    height: 50px;
    color: #2f2f2f;
    border-bottom: 2px solid #333333;
}

.general--container {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.about-me--container {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    clip-path: inset(0px 0px -15px 0px);
    background-image: url(../img/benim.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% 100%;
    background-blend-mode: darken;
    padding: 0 20px;
}

.about-me--text {
    padding-top: 20px;
    width: 50%;
    
    /* margin-right: 20px */
}

.about-me--text > p {
    font-size: 1.2rem;
    color: #FFFFFF;
    /* text-shadow: 1px 1px 2px var(--accent-color), -1px -1px 2px var(--accent-color), 1px -1px 2px var(--accent-color), -1px 1px 2px var(--accent-color), 0px 0px 2px var(--accent-color); */
    
    /* mix-blend-mode: hard-light; */
    
}
/* 
.about-me--text > p:nth-child(2) {
    background-color: rgb(0, 0, 0, 0.5);
} */

.competence--container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.competence--container > h3 {
    padding: 0 20px;
}

.competence--container--inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.competence--row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.competence--button {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75); 
    clip-path: inset(0px 0px -15px 0px);
}

.competence--item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    cursor: pointer;
    padding: 40px 0;
}

.competence--item > img {
    height: 100px;
    object-fit: contain;
}

.competence--item > h3 {
    margin: 10px 0 0 0;
}

.popup--modal {
    display: none;
    width: 100%;
    background-color: var(--accent-color);
    overflow: hidden;
    max-height: 0;
    text-align: center;
}

.popup--modal.discord {
    left: -200%;
    width: 500%;
    bottom: calc(100% + 10px);
}

.popup--modal--arrow.discord {
    top: -10px;
}

#react {
    position:relative;
}

#react::after {
    content: '';	
    position: absolute;
    width: 50px;
    height: 50px;
    top: 40px;
    right: 55%;
    box-shadow: 
    2px 2px 0px 0px var(--pulse-color), 
    -2px -2px 0px 0px var(--pulse-color),
    -2px 2px 0px 0px var(--pulse-color),
    2px -2px 0px 0px var(--pulse-color),
    0 0 6px 0px var(--pulse-accent-color), 
    0 0 6px 0px var(--pulse-accent-color),
    0 0 6px 0px var(--pulse-accent-color),
    0 0 6px 0px var(--pulse-accent-color)
    ;
    border-radius: 50%;
    animation: pulse 5s ease-in-out 75ms infinite;
}

#react::before {
    content: '';	
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50px;
    right: calc(55% + 10px);
    box-shadow: 
    2px 2px 0px 0px var(--pulse-color), 
    -2px -2px 0px 0px var(--pulse-color),
    -2px 2px 0px 0px var(--pulse-color),
    2px -2px 0px 0px var(--pulse-color),
    0 0 6px 0px var(--pulse-accent-color), 
    0 0 6px 0px var(--pulse-accent-color),
    0 0 6px 0px var(--pulse-accent-color),
    0 0 6px 0px var(--pulse-accent-color)
    ;
    border-radius: 50%;
    animation: pulse 5s ease-in-out infinite;
}

.popup--modal > h3 {
    margin-top: 20px;
}

.popup--modal > p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 20px;
}

.personal--info {
    padding: 20px;
}

.features--container {
    padding-top: 20px;
}

.features--text {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.features--list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 50px;
}

.features--item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--text-color);
}

.features--item--inner {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bottom--line {
    width: 0;
    height: 6px;
    box-shadow: 0 2px 0 0 rgb(165, 0, 0), 0 0 6px 0 rgb(255, 0, 0);
    clip-path: inset(0px 0px -6px 0px);
    margin-bottom: 20px;
    transform: translateY(-6px);
}

.bottom--line--static {
    width: 100%;
    height: 1px;
    box-shadow: 0 1px 0 0 var(--text-color);
    margin-top: 10px;
}

.features--item > .features--item--inner > h3 {
    margin: 0;
}

.asterisk {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    flex-shrink: 0;
}

.closingDiv {
    display: none;
    position:absolute;
    inset: 0 -100vh 100px -100vh;
}

.about--links--container {
    display: none;
    position: fixed;
    height: 300px;
    width: 100%;
    max-width: 800px;
    bottom: 100px;
    flex-direction: column;
    justify-content: space-evenly;
    background: #000000;
    color: var(--accent-color);
}

.about--button--linkedin {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.about--buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.links--button {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(223, 145, 2);
    cursor: pointer;
    color: #FFFFFF;
}

.links--button > h1 {
    margin: 0;
}

.links--button.features {
    background-image: url(../img/gears.png);
    background-repeat: no-repeat;
    background-position: 50% 20%;
    background-size: 20rem;
    background-blend-mode: overlay;
}

.links--button.aboutme {
    background-image: url(../img/aboutme.png);
    background-repeat: no-repeat;
    background-size: 12rem;
    background-blend-mode: overlay;
    background-position: 50% 100%;
    background-color: rgb(57, 164, 0);
}

.links--button.linkedIn {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0a66c2;
    color: #FFFFFF;
    text-decoration: none;
}

/* Auth specific ----------------------------------------------------------------------------------------------*/

.signup--card {
    position: absolute;
    bottom: 0;
    margin-bottom: 100px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgb(254, 255, 248);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.234);
    clip-path: inset(-15px 0px 0px 0px);
    transform: translateY(0%);
    animation: authFadeIn 0.3s ease-in-out forwards;
}

.signup--card--header {
    display: flex;
    justify-content: center;
}

.input--field {
    border: none;
    box-shadow: 0 1px 0 0 #2f2f2f;
    padding: 5px 10px;
    outline: none;
    width: 100%;
    background: transparent;
}

.auth--button {
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
    border-bottom: 2px solid #111;
    box-shadow: 0 0 3px 0 #1114;
    background: #c3c3c3;
}

.signup--card--footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}


.header {
    background-color: var(--root-background-color);
}

@media (max-width: 1100px) {
    .features--button {
        position:absolute;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        left: 0;
        top: 50%;
        transform: translateX(-102px) translateY(100%) rotate(90deg);
    }
    
    .features--button > h1 {
        margin: 0;
    }
    
    .about--button {
        position:absolute;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        right: 0;
        top: 50%;
        transform: translateX(102px) translateY(100%) rotate(-90deg);
    }
    
    .about--button > h1 {
        margin: 0;
    }
}