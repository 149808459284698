@keyframes arrow {
    0% {
        transform: translateX(0px);
    }

    75% {
        transform: translateX(3px);
    }

    87.5% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes flipArrow {
    from {
        transform: rotateZ(90deg) rotateY(0deg);
    }

    to {
        transform: rotateZ(90deg) rotateY(180deg);
    }
}

@keyframes lightToDark {
    0% {
        border: 2px solid #000000;
    }

    100% {
        border: 2px solid #cacaca;
    }
}

@keyframes darkToLight {
    0% {
        border: 2px solid #cacaca;
    }

    100% {
        border: 2px solid #000000;
    }
}

@keyframes dotLeftToRight {
    0% {
        background: #000000;
        transform: translateX(0px);
    }

    100% {
        background: #cacaca;
        transform: translateX(30px);
    }
}

@keyframes dotRightToLeft {
    0% {
        background: #cacaca;
        transform: translateX(30px);
    }

    100% {
        background: #000000;
        transform: translateX(0px);
    }
}

/* @keyframes lightToDarkBody {
    0% {
        background-color: rgb(25, 25, 29);
    }

    100% {
        background-color: rgb(210, 210, 210);
    }
}

@keyframes darkToLightBody {
    0% {
        background-color: rgb(254, 255, 248);
        background-color: rgb(210, 210, 210);
    }

    100% {
        background-color: rgb(25, 25, 29);
    }
} */

@keyframes lightToDarkBody {
    0% {
        filter: brightness(1);
    }

    100% {
        filter: brightness(0.5);
    }
}

@keyframes darkToLightBody {
    0% {
        filter: brightness(0.5);
    }

    100% {
        filter: brightness(1);
    }
}

@keyframes lightToDarkRoot {
    0% {
        background: rgb(35, 35, 39);
    }

    100% {
        background: rgb(254, 255, 248);
    }
}

@keyframes darkToLightRoot {
    0% {
        background: rgb(254, 255, 248);
    }

    100% {
        background: rgb(35, 35, 39);
    }
}

@keyframes other-cat-selected {
    0% {
        opacity: 100%
    }

    99% {
        opacity: 100%
    }

    100% {
        opacity: 40%
    }
}

@keyframes sub-category-reveal {
    from {
        transform: translateY(140%);
    }

    to {
        transform: translateY(-100%);
    }
}

@keyframes sub-category-hide {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(140%);
    }
}

@keyframes main-category-hide {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(100%);
    }
}

@keyframes main-category-reveal {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes slide-in {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes slide-in-desktop {
    from {
        transform: translateY(100%) translateX(-50%);
    }

    to {
        transform: translateY(0%) translateX(-50%);
    }
}

@keyframes arrow {
    0% {
        transform: translateX(0px) scaleX(-100%);
    }

    75% {
        transform: translateX(0px) scaleX(-100%);
    }

    87.5% {
        transform: translateX(7px) scaleX(-100%);
    }

    100% {
        transform: translateX(0px) scaleX(-100%);
    }
}


/* @keyframes selectedSuspense {
    0% {
        transform: translate3d(0, 0, 0) rotateZ(0deg);
    }

    10% {
        transform: translate3d(0px, 0, 0) rotateZ(0deg);
    }

    20% {
        transform: translate3d(1px, 0, 0) rotateZ(0.2deg);
    }

    30% {
        transform: translate3d(2px, 0, 0) rotateZ(0.5deg);
    }

    50% {
        transform: translate3d(2px, 0, 0) rotateZ(0.8deg);
    }

    60% {
        transform: translate3d(1px, 0, 0) rotateZ(0.5deg);
    }

    70% {
        transform: translate3d(1px, 0, 0) rotateZ(-0.5deg);
    }

    77% {
        transform: translate3d(2px, 0, 0) rotateZ(0deg);
    }

    85% {
        transform: translate3d(1px, 0, 0) rotateZ(0.3deg);
    }

    93% {
        transform: translate3d(1px, 0, 0) rotateZ(-0.3deg);
    }

    96% {
        transform: translate3d(1px, 0, 0) rotateZ(-0.1deg);
    }

    100% {
        transform: translate3d(0px, 0, 0) rotateZ(0deg);
    }
} */

/* @keyframes selectedSuspense {
    0% {
        box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.3);
        transform: scale(1);
    }

    50% {
        box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.3);
        transform: scale(1.03);
    }

    80% {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        transform: scale(1.05);

    }

    85% {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        transform: scale(1.06);
    }

    90% {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        transform: scale(1.07);
    }

    92.5% {
        box-shadow: 0 0 2.5px 0 rgba(0, 0, 0, 0.3);
        transform: scale(1.05);
    }

    100% {
        box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.3);
        transform: scale(1);
    }
} */

@keyframes selectedSuspense {
    0% {
        box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.3);
        opacity: 1;
    }

    40% {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        opacity: 0.6;
    }

    50% {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        opacity: 0.5;
    }

    60% {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        opacity: 0.6;
    }

    100% {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        opacity: 1;
    }
}

@keyframes correctAnswer {
    0% {
        opacity: 100%;
    }

    40% {
        opacity: 100%;
    }

    60% {
        opacity: 70%;
    }

    99% {
        opacity: 70%;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes quizCountdown {
    0% {
        width: 100%;
        box-shadow: 0px 0px 5px 1px rgb(0, 255, 102, 0.4);
    }

    50% {
        width: 50%;
        box-shadow: 0px 0px 5px 1px rgb(0, 255, 102, 0.4);
        background: rgb(0, 255, 102);
    }

    75% {
        width: 25%;
        box-shadow: 0px 0px 5px 1px rgb(255, 247, 0, 0.4);
        background: rgb(255, 247, 0);
    }

    87.5% {
        width: 12.5%;
        box-shadow: 0px 0px 5px 1px rgb(255, 221, 0, 0.4);
        background: rgb(255, 221, 0);
    }

    100% {
        width: 0%;
        box-shadow: 0px 0px 5px 1px rgb(255, 0, 0, 0.4);
        background: rgb(255, 0, 0);
    }
}

@keyframes timeRanOut {
    0% {
        box-shadow: 0px 0px 5px 1px rgb(255, 0, 0, 0.4);
        background: rgb(255, 0, 0);
    }

    40% {
        box-shadow: 0px 0px 5px 1px rgb(255, 0, 0, 0.4);
        background: rgb(255, 0, 0);
    }

    60% {
        box-shadow: 0px 0px 5px 1px rgb(170, 0, 0, 0.4);
        background: rgb(170, 0, 0);
    }

    99% {
        box-shadow: 0px 0px 5px 1px rgb(170, 0, 0, 0.4);
        background: rgb(170, 0, 0);
    }

    100% {
        box-shadow: 0px 0px 5px 1px rgb(255, 0, 0, 0.4);
        background: rgb(255, 0, 0);
    }
}

@keyframes labelFadeOutOfInput {
    to {
        transform: translateY(5px) translatex(0px);
    }
}

@keyframes labelFadeIntoInput {
    from {
        transform: translateY(5px) translatex(0px);
    }

    to {
        transform: translateY(25px) translatex(2px);
    }
}

@keyframes enlargeBorder {
    to {
        box-shadow: 0 2px 0 0 rgb(138, 0, 0);
    }
}

@keyframes smollerBorder {
    from {
        box-shadow: 0 2px 0 0 rgb(138, 0, 0);
    }

    to {
        box-shadow: 0 1px 0 0 #3f3f3f;
    }
}

@keyframes authFadeIn {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes alertSlideIn {
    0% {
        transform: translateY(-100px);
    }

    7% {
        transform: translateY(115px);
    }

    10% {
        transform: translateY(105px);
    }

    90% {
        transform: translateY(105px);
    }

    93% {
        transform: translateY(120px);
    }

    100% {
        transform: translateY(-100px);
    }
}

@keyframes notificationSlideIn {
    0% {
        transform: translateY(-200px);
    }

    7% {
        transform: translateY(15px);
    }

    10% {
        transform: translateY(10px);
    }

    90% {
        transform: translateY(10px);
    }

    93% {
        transform: translateY(15px);
    }

    100% {
        transform: translateY(-200px);
    }
}

@keyframes fade-in {
    from {
        transform: scale(0%)
    }

    to {
        transform: scale(100%)
    }
}

@keyframes fade-out {
    from {
        transform: scale(100%)
    }

    to {
        transform: scale(0%)
    }
}

@keyframes fade-title {
    from {
        opacity: 0%
    }

    to {
        opacity: 100%
    }
}

@keyframes showModal {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }

}

@keyframes hideModal {
    from {
        opacity: 100%;
    }

    to {
        opacity: 0%;
    }

}

@keyframes showMobileModal {
    from {
        max-height: 0px;
    }

    to {
        max-height: 500px;
    }
}

@keyframes hideMobileModal {
    from {
        max-height: 500px;
    }

    to {
        max-height: 0px;
    }
}

@keyframes showLine {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }

}

@keyframes hideLine {
    from {
        width: 100%;
    }

    to {
        width: 0%;
    }

}

@keyframes turnAsteriskClockwise {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(60deg);
    }
}

@keyframes turnAsteriskCounterClockwise {
    from {
        transform: rotate(60deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@keyframes shakeButton {
    0% {
        transform: translateY(-20px);
        box-shadow:
            inset 0px -25px 0 0 rgba(0, 0, 0, 0.25),
            0 5px 0px 0 #545454,
            0 5px 10px 0 #545454,
            inset 0 -10px 0 0 #545454,
            inset 0 3px 0 0 #da0f00,
            inset 0 10px 0 0 rgba(255, 255, 255, 0.25);
    }

    85% {
        transform: translateY(-20px);
        box-shadow:
            inset 0px -25px 0 0 rgba(0, 0, 0, 0.25),
            0 5px 0px 0 #545454,
            0 5px 10px 0 #545454,
            inset 0 -10px 0 0 #545454,
            inset 0 3px 0 0 #da0f00,
            inset 0 10px 0 0 rgba(255, 255, 255, 0.25);
    }

    90% {
        transform: translateY(-15px);
        box-shadow:
            inset 0px -25px 0 0 rgba(0, 0, 0, 0.25),
            0 5px 0px 0 #545454,
            0 5px 15px 0 #303030,
            inset 0 -10px 0 0 #545454,
            inset 0 3px 0 0 #da0f00,
            inset 0 10px 0 0 rgba(255, 255, 255, 0.25);
    }

    100% {
        transform: translateY(-20px);
        box-shadow:
            inset 0px -25px 0 0 rgba(0, 0, 0, 0.25),
            0 5px 0px 0 #545454,
            0 5px 10px 0 #545454,
            inset 0 -10px 0 0 #545454,
            inset 0 3px 0 0 #da0f00,
            inset 0 10px 0 0 rgba(255, 255, 255, 0.25);
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    80% {
        opacity: 1;
        transform: scale(1);
    }

    82.5% {
        opacity: 30%;
        transform: scale(0.85);
    }

    85% {
        opacity: 5%;
        transform: scale(1.15);
    }

    87.5% {
        opacity: 1;
        transform: scale(1.15);
    }

    90% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes revealAboutLinks {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes hideAboutLinks {
    from {
        transform: translateY(0%);
    }
    
    to {
        transform: translateY(100%);
    }
}

@keyframes loadingDot {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    60% {
        transform: scale(1);
        opacity: 0;
    }

    80% {
        transform: scale(1.25);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes reminder {
    0% {
        opacity: 0.5;
    }

    10% {
        opacity: 0.75;
    }

    90% {
        opacity: 0.75;
    }

    100% {
        opacity: 0.5;
    }
}